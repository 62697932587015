import React from 'react'

import '../styles/gallery_modal.scss'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Gallery_1 from '../images/gallery/1.jpg'
import Gallery_2 from '../images/gallery/2.jpg'
import Gallery_3 from '../images/gallery/3.jpg'
import Gallery_4 from '../images/gallery/4.jpg'
import Gallery_5 from '../images/gallery/5.jpg'
import Gallery_6 from '../images/gallery/6.jpg'
import Gallery_7 from '../images/gallery/7.jpg'
import Gallery_8 from '../images/gallery/8.jpg'
import Gallery_9 from '../images/gallery/9.jpg'
import Gallery_10 from '../images/gallery/10.jpg'
import Gallery_11 from '../images/gallery/11.jpg'

const gallery = [
	Gallery_1,
	Gallery_2,
	Gallery_3,
	Gallery_4,
	Gallery_5,
	Gallery_6,
	Gallery_7,
	Gallery_8,
	Gallery_9,
	Gallery_10,
	Gallery_11
]

export default function GalleryModal({ closeModal }) {
	var settings = {
		dots: true,
		infinite: true,
		accessibility: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 6000,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false
				}
			}
		]
	}
	return (
		<div className="gallery">
			<div className="close" onClick={closeModal} />
			<Slider {...settings} className="gallery__sliders-container">
				{gallery.map((item) => {
					return <img src={item} alt="Ínsula Living Galeria 1" />
				})}
			</Slider>
		</div>
	)
}
