import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import GalleryModal from '../../../components/GalleryModal'
import Loader from '../../../components/Loader'

import '../../../styles/insula-living.scss'

// import MainBuild from '../../images/main_build.jpg'

export default function InsulaLiving({ location }) {
	const [ isLoading, setLoading ] = useState(true)
	const [ showGalleryModal, setGalleryModal ] = useState(false)
	/* useEffect(() => {
		if (document.getElementById('model'))
			document.getElementById('model').addEventListener('load', () => {
				setLoading(false)
			})
	}, []) */

	function closeModal() {
		if (showGalleryModal) {
			setGalleryModal(false)
		}
	}
	return (
		<Layout currentPath={location}>
			<SEO title="Ínsula Living | Sobre Nosotros" />
			<div className="insula">
				{showGalleryModal ? <GalleryModal closeModal={closeModal} /> : null}

				<Link to="/#nosotros" className="back">
					&larr;Volver
				</Link>
				<div className="insula__title">
					<div className="insula__texture" />
					<h1>ÍNSULA LIVING JUANANBÚ</h1>
				</div>
				<div className="insula__content">
					{/* <div className="insula__buttons-container">
						<a
							href="https://www.google.com.co/maps/place/%C3%8Dnsula+Living+Juanamb%C3%BA/@3.4536011,-76.5394748,15z/data=!4m5!3m4!1s0x0:0x89028d00ce19b57e!8m2!3d3.4536011!4d-76.5394748"
							target="_blank"
							className="mapa"
						>
							Mapa
						</a>
						<button className="galeria" onClick={() => setGalleryModal(true)}>
							Galeria
						</button>
						<Link to="/proyectos/insula-living/tipos">Tipos de apartamentos</Link>
						<a href="../../../Plantas Ínsula Living Juanambú.pdf" target="_blank" className="tipos">
							Tipos de apartamentos
						</a>
						<a href="../../../INSULA_LIVING_JUANANBU.pdf" target="_blank" className="brochure">
							Brochure
						</a>
					</div> */}
					<div className="insula__buttons-container">
						<a
							href="https://www.google.com.co/maps/place/%C3%8Dnsula+Living+Juanamb%C3%BA/@3.4536011,-76.5394748,15z/data=!4m5!3m4!1s0x0:0x89028d00ce19b57e!8m2!3d3.4536011!4d-76.5394748"
							target="_blank"
							className="mapa"
						>
							Mapa
						</a>
						<button className="galeria" onClick={() => setGalleryModal(true)}>
							Galeria
						</button>
						<Link to="/proyectos/insula-living/tipos">Tipos de apartamentos</Link>
						<a href="../../../INSULA_LIVING_JUANANBU.pdf" target="_blank" className="brochure">
							Brochure
						</a>
					</div>

					{/* <div id="card">
						{isLoading ? (
							<div className="loader-container">
								<p>Cargando modelo 3D</p>
								<Loader />
							</div>
						) : null}
						<model-viewer
							id="model"
							src="../../../Apartamento.glb"
							alt="Ínsula Juananbú"
							shadow-intensity="1"
							camera-controls
							interaction-prompt="auto"
							ar
							auto-rotate
							magic-leap
							loading="eager"
						/>
					</div> */}
				</div>
			</div>
		</Layout>
	)
}
